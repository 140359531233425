export enum PetIcon {
    Icon_Accuracy = require('./Icon_Accuracy.png'),
    Icon_Archmastery = require('./Icon_Archmastery.png'),
    Icon_Armor_Penetration = require('./Icon_Armor_Penetration.png'),
    Icon_CriticalRating = require('./Icon_CriticalRating.png'),
    Icon_Pet_Agility = require('./Icon_Pet_Agility.png'),
    Icon_Pet_Intellect = require('./Icon_Pet_Intellect.png'),
    Icon_Pet_Power = require('./Icon_Pet_Power.png'),
    Icon_Pet_Strength = require('./Icon_Pet_Strength.png'),
    Icon_Pet_Will = require('./Icon_Pet_Will.png'),
    Type_Resist = require('./Type_Resist.png'),
    Type_Damage = require('./Type_Damage.png'),
    Type_MaxHealth = require('./Type_MaxHealth.png'),
    Type_MaxMana = require('./Type_MaxMana.png'),
    Type_PowerPips = require('./Type_PowerPips.png'),
    Type_Stun_Resist = require('./Type_Stun_Resist.png'),
    Icon_BlockRating = require('./Icon_BlockRating.png'),
    Type_Incoming = require('./Type_Incoming.png'),
    Type_Outgoing = require('./Type_Outgoing.png'),
}