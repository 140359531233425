import './style.less'
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Button, Col, InputNumber, Row, Slider, Dropdown, Popover, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
export function SlideInput(props: any) {
    const { popTitle, title, icon} = props
    const [value, setValue] = useState(0)
    useEffect(()=>{
        props.onChange(value)
    },[value])
    return <Row className="slideInput" gutter={10} justify='start'>
        <Col span={2} style={{cursor: 'pointer', textAlign:'right'}}>
            <Tooltip placement='bottom' title={<>{popTitle}:{title}</>}><img width={25} src={icon} alt=''/></Tooltip>
        </Col>
        <Col span={2}>
            <InputNumber size='middle' controls={false} onChange={(number)=>{
                setValue(number || 0)
            }} value={value} min={0} max={550} />
        </Col>
        <Col span={2}>
            <Dropdown 
            trigger={['contextMenu']}
            menu={{
                items: [{
                    key: '-0',
                    label: '-25',
                    onClick: ()=>{
                        if(value >= 25){
                            setValue(value - 25)
                        }else{
                            setValue(0)
                        }
                    }
                }, {
                    key: '-0',
                    label: '-40',
                    onClick: ()=>{
                        if(value >= 40){
                            setValue(value - 40)
                        }else{
                            setValue(0)
                        }
                    }
                }, {
                    key: '-0',
                    label: '-65',
                    onClick: ()=>{
                        if(value >= 65){
                            setValue(value - 65)
                        }else{
                            setValue(0)
                        }
                    }
                }]
            }}>
                <Button title="-1" onClick={()=>setValue(value-1)} icon={<MinusCircleOutlined />} />
            </Dropdown>
        </Col>
        <Col span={15}>
            <Slider
                className='slider'
                value={value}
                tooltip={{
                    placement:'bottom'
                }}
                keyboard
                min={0}
                max={550}
                onChange={(number)=>{
                    setValue(number)
                }}
            />
        </Col>
        <Col span={2}>
            <Dropdown trigger={['contextMenu']} menu={{
                items: [{
                    key: '+0',
                    label: '+25',
                    onClick: ()=>{
                        setValue(value + 25)
                    }
                }, {
                    key: '+0',
                    label: '+40',
                    onClick: ()=>{
                        setValue(value + 40)
                    }
                }, {
                    key: '+0',
                    label: '+65',
                    onClick: ()=>{
                        setValue(value + 65)
                    }
                }]
            }}>
                <Button onClick={()=>setValue(value+1)} title="+1" icon={<PlusCircleOutlined />} />
            </Dropdown>
        </Col>
    </Row>
}