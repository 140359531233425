import { Button, Col, Row, Tooltip } from "antd"
import Title from "antd/es/typography/Title"
import { useEffect, useRef, useState } from "react"
import { PetIcon } from "./image"
import { SlideInput } from "./SlideInput"
import data from './calculate.json'
import './style.less'
interface calculateData {
    data_right: any[],
    data: any[]
}
export function Calculate() {
    const [attr, setAttr] = useState([0, 0, 0, 0, 0])
    const attrIcons = useRef([
        PetIcon.Icon_Pet_Strength,
        PetIcon.Icon_Pet_Intellect,
        PetIcon.Icon_Pet_Agility,
        PetIcon.Icon_Pet_Will,
        PetIcon.Icon_Pet_Power
    ])
    const [calculate, setCalculate] = useState<calculateData>(data)
    
const calculateGs = (item: any, attr: number[]) => {
    const { x, y, z, de, type } = item
    const xValue = attr[x]
    const yValue = attr[y]
    const zValue = attr[z]
    switch (type) {
        case 1:
            return ((2 * xValue + 2 * yValue + zValue) / de).toFixed(2)
        case 2:
            return ((2 * xValue + 2 * yValue + zValue) * 3 / de).toFixed(2)
        case 3:
            return ((2 * xValue + 2 * yValue + zValue) * 9 / de).toFixed(2)
        default:
            return 1
    }
}
    useEffect(() => {

    }, [])
    useEffect(() => {

    }, [attr])
    const AttrChange = (index: number, number: number) => {
        attr[index] = number
        setAttr([...attr])
    }
    return <div className="calculate">
        {
            calculate.data.map((item, idx) => {
                return <SlideInput key={idx} icon={attrIcons.current[idx]} title={item.zh_name} popTitle={item.name} onChange={(number: number) => AttrChange(idx, number)} />
            })
        }
        <Row>
            {
                calculate.data_right.map((data, idx) => {
                    let icon: string
                    if (data.icon) {
                        icon = PetIcon[data.icon]
                        return <Col span={4} key={data.type}>
                            <ul>
                                <Title level={5}>{data.type}</Title>
                                {
                                    data.children && data.children.map((item: any, idx: number) => {
                                        return <li key={item.name}>
                                            <Tooltip title={<>
                                                {/* <Row>{item.zh_name}</Row> */}
                                                <Row>{item.name}</Row>
                                            </>}>
                                                <Button
                                                    type='text'
                                                    icon={<img className="spell-icon" src={icon} alt='' />}>
                                                    {calculateGs(item, attr)}{data.unit}
                                                </Button>
                                            </Tooltip>
                                        </li>
                                    })
                                }
                            </ul>
                        </Col>
                    } else {
                        return <Col span={4} key={data.type}>
                            <ul>
                                <Title level={5}>{data.type}</Title>
                                {
                                    data.children && data.children.map((item: any, idx: number) => {
                                        icon = String(PetIcon[item.icon])
                                        return <li key={item.name}>
                                            <Tooltip title={<>
                                                {/* <Row>{item.zh_name}</Row> */}
                                                <Row>{item.name}</Row>
                                            </>}>
                                                <Button
                                                    type='text'
                                                    icon={<img className="spell-icon" src={icon} alt='' />}>
                                                    {calculateGs(item, attr)}{data.unit}
                                                </Button>
                                            </Tooltip>
                                        </li>
                                    })
                                }
                            </ul>
                        </Col>
                    }
                })
            }
        </Row>
    </div>
}