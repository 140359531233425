import './App.less';
import { useEffect } from 'react';
import { CalculatePage } from './page/calculate';

function App() {
  useEffect(() => {
    return () => {
      // window.electronApi.unWatchAudio()
    }
  }, [])
  return (
    <div className="App">
      <CalculatePage/>
    </div>
  );
}

export default App;
